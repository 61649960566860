import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cloud-migration',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cloud-migration.component.html',
  styleUrl: './cloud-migration.component.scss'
})
export class CloudMigrationComponent {
  migrateCloudData:any = [
    {
      icon: "assets/images/cloud_migration/enhanced performance.svg",
      service_name: "Enhanced Performance",
      description: "Migrate to a cloud platform that better suits your performance needs, providing faster processing speeds, lower latency, and improved reliability."
    },
    {
      icon: "assets/images/cloud_migration/cost efficiency.svg",
      service_name: "Cost Efficiency",
      description: "Take advantage of more competitive pricing models and cost-saving opportunities available on different cloud platforms."
    },
    {
      icon: "assets/images/cloud_migration/advanced features.svg",
      service_name: "Advanced Features and Services",
      description: "Access unique features, tools, and services that may not be available on your current cloud platform, enhancing your capabilities and innovation potential."
    },
    {
      icon: "assets/images/cloud_migration/scalability.svg",
      service_name: "Scalability and Flexibility",
      description: "Optimize your cloud environment for greater scalability and flexibility, ensuring it can grow and adapt alongside your business."
    },
  ]


  migrationServicesData:any = [
    {
      icon: "assets/images/cloud_migration/strategic.svg",
      service_name: "Strategic Planning and Assessment",
      description: "We begin with a thorough assessment of your current cloud infrastructure, understanding your goals, and designing a strategic migration plan tailored to your needs."
    },
    {
      icon: "assets/images/cloud_migration/architecture.svg",
      service_name: "Architecture Design and Optimization",
      description: "Our experts design an optimized cloud architecture on your new platform, ensuring it aligns with your business objectives and leverages the best features of the new environment."
    },
    {
      icon: "assets/images/cloud_migration/seamless.svg",
      service_name: "Seamless Data Transfer",
      description: "We manage the transfer of your data with precision, ensuring data integrity and security throughout the migration process. Our methodologies minimize downtime and ensure business continuity."
    },
    {
      icon: "assets/images/cloud_migration/application migration.svg",
      service_name: "Application Migration and Testing",
      description: "Our team handles the migration of your applications, conducting comprehensive testing to ensure they function perfectly in the new cloud environment."
    },
    {
      icon: "assets/images/cloud_migration/security and complianc.svg",
      service_name: "Security and Compliance Assurance",
      description: "We implement robust security measures and ensure compliance with all relevant regulations, protecting your data and maintaining trust."
    },
    {
      icon: "assets/images/cloud_migration/post migration.svg",
      service_name: "Post-Migration Support",
      description: "Our commitment doesn’t end with the migration. We provide ongoing support and optimization services to ensure your new cloud environment operates at peak efficiency."
    },
  ]

  migrationUseCases:any = [
    {
      icon: "assets/images/cloud_migration/aws to gcp.svg",
      service_name: "AWS to Google Cloud Platform (GCP)",
      description: "A global retail company migrated from AWS to GCP to leverage Google’s advanced data analytics and AI tools, resulting in improved customer insights and more personalized marketing strategies."
    },
    {
      icon: "assets/images/cloud_migration/azure to aws.svg",
      service_name: "Microsoft Azure to AWS",
      description: "A tech startup transitioned from Azure to AWS to take advantage of AWS’s superior scalability and global reach, enabling faster application deployment and better performance in international markets."
    },
    {
      icon: "assets/images/cloud_migration/hybrid.svg",
      service_name: "Hybrid Cloud Enhancements",
      description: "A financial services firm migrated parts of its workloads from an on-premises data center to AWS while maintaining some services on Azure, achieving a robust hybrid cloud environment that optimized costs and improved disaster recovery capabilities."
    },
  ]
  cloudMigrationData:any = [
    {
      icon: "assets/images/cloud_migration/proven expertise.svg",
      service_name: "Proven Expertise",
      description: "With a track record of successful cloud migrations, our team has the expertise to handle complex transitions smoothly and efficiently."
    },
    {
      icon: "assets/images/cloud_migration/customised solutions.svg",
      service_name: "Customized Solutions",
      description: "We tailor our migration strategies to meet the specific needs of your business, ensuring a perfect fit and maximum benefit."
    },
    {
      icon: "assets/images/cloud_migration/comprehensive support.svg",
      service_name: "Comprehensive Support",
      description: "From planning to post-migration support, we provide end-to-end services that ensure a seamless transition and continuous optimization."
    }
  ]
  
  
  dynamicContent: any;
  
  cloudContent: any = {
    heading: 'Ready to Elevate Your Cloud Strategy?',
    description: 'Contact us today to learn how our cloud solutions and strategy services can transform your business.',
  
  }
  
  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }
  
  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }
}
