import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../../../services/canonical.service';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { OdooServicesComponent } from "../odoo-services/odoo-services.component";
import { FaqComponent } from "../../faq/faq.component";

@Component({
  selector: 'app-implementation',
  standalone: true,
  imports: [OdooServicesComponent, FaqComponent],
  templateUrl: './implementation.component.html',
  styleUrl: './implementation.component.scss'
})
export class ImplementationComponent {


  odooPartner = [
    {
      icon: "assets/images/odoo/development.svg",
      service_name: "Proven Expertise in Odoo Implementation",
      description: "With years of experience in Odoo, our team of certified experts is well-equipped to handle every aspect of the implementation process. From initial consultation and planning to deployment and training, we ensure a smooth transition to your new ERP system."
    },
    {
      icon: "assets/images/odoo/studio.svg",
      service_name: "Tailored Implementation Strategy",
      description: "At AttributeX, we recognize that every business is unique. That's why we develop a customized implementation strategy that aligns with your specific goals, industry requirements, and existing processes, ensuring that Odoo integrates seamlessly into your operations."
    },
    {
      icon: "assets/images/odoo/automation.svg",
      service_name: "Comprehensive Support and Training",
      description: "Our commitment to your success extends beyond the implementation phase. We provide extensive training for your team to ensure they are confident in using Odoo's features. Plus, our dedicated support team is always available to assist with any questions or challenges that arise post-implementation."
    },
    {
      icon: "assets/images/odoo/consultants.svg",
      service_name: "Scalable Solutions for Growing Businesses",
      description: "Whether you are a small startup or a large enterprise, our scalable Odoo solutions grow with your business. We implement Odoo in a way that allows you to add modules and features as your business evolves, ensuring you always have the tools you need to succeed."
    }
  ];

  implementationProcess = [
    {
      icon: "assets/images/odoo/Analysis.svg",
      service_name: "Consultation and Analysis",
      description: "We begin by understanding your business needs, goals, and challenges. This allows us to design an implementation plan that aligns with your strategic objectives."
    },
    {
      icon: "assets/images/odoo/Customized_Solution.svg",
      service_name: "Customized Solution Design",
      description: "Based on our analysis, we tailor the Odoo system to meet your specific requirements, ensuring it integrates smoothly with your current processes."
    },
    {
      icon: "assets/images/odoo/development.svg",
      service_name: "Implementation and Testing",
      description: "Our team handles the full deployment of Odoo, rigorously testing each component to ensure it works flawlessly in your environment."
    },
    {
      icon: "assets/images/odoo/Training.svg",
      service_name: "Training and Go-Live",
      description: "We provide in-depth training for your staff, ensuring they are fully prepared to use the new system. Once everything is set, we go live with your new Odoo ERP."
    },
    {
      icon: "assets/images/odoo/consultants.svg",
      service_name: "Ongoing Support and Optimization",
      description: "Post-implementation, we continue to support your business, offering maintenance, updates, and optimization services to keep your system running at peak performance."
    }
  ];


  accordionData = [
    {
      title: "What is Odoo implementation, and why is it important?",
      content: `Odoo implementation refers to the process of deploying the Odoo ERP system within your organization. This includes setting up the software, configuring it according to your business needs, integrating it with your existing systems, and training your staff. Successful implementation is crucial because it ensures that Odoo works effectively within your business environment, helping you streamline operations and improve efficiency.`
    },
    {
      title: "How does AttributeX approach Odoo implementation?",
      content: `AttributeX follows a structured approach to Odoo implementation. We start with a detailed consultation to understand your business needs. Then, we design a customized solution, implement the system, conduct thorough testing, and provide training to your team. Our process is collaborative, ensuring that the final solution is tailored to your specific requirements.`
    },
    {
      title: "How long does the Odoo implementation process take?",
      content: `The duration of the Odoo implementation process depends on the complexity and scale of your project. A basic implementation may take a few weeks, while more complex deployments could take several months. During the initial consultation, we’ll provide a detailed timeline based on your specific needs.`
    },
    {
      title: "What types of businesses can benefit from Odoo implementation?",
      content: `Odoo is highly versatile and can benefit businesses of all sizes and industries. Whether you're a small startup looking to streamline operations or a large enterprise needing an integrated ERP solution, Odoo can be customized to meet your specific needs. AttributeX has experience working with businesses across various sectors, including manufacturing, retail, healthcare, and more.`
    },
    {
      title: "What kind of support does AttributeX provide after the implementation is complete?",
      content: `AttributeX offers comprehensive post-implementation support, including ongoing maintenance, troubleshooting, and updates. We also provide training for your team to ensure they are confident in using the new system. Our support doesn’t stop after go-live; we’re here to help your business continue to grow and evolve with Odoo.`
    },
    {
      title: "How do I know if Odoo is the right ERP solution for my business?",
      content: `Odoo is a modular and highly flexible ERP system that can be tailored to fit a wide range of business needs. During our initial consultation, we’ll assess your current processes, challenges, and goals to determine if Odoo is the right fit for your business. We’ll also explain how Odoo can be customized to address your specific requirements.`
    },
    {
      title: "Can AttributeX integrate Odoo with my existing software systems?",
      content: `Yes, AttributeX can integrate Odoo with your existing software systems, such as CRM tools, e-commerce platforms, and industry-specific applications. Our team ensures seamless integration to enable smooth data flow and enhance overall operational efficiency.`
    },
    {
      title: "What costs are involved in Odoo implementation with AttributeX?",
      content: `The cost of Odoo implementation varies depending on the scope and complexity of your project. During our initial consultation, we’ll provide you with a detailed quote that outlines all costs associated with the implementation, including any customization, integration, and training services.`
    },
    {
      title: "What is the difference between Odoo implementation and customization?",
      content: `Odoo implementation involves setting up and configuring the Odoo ERP system for your business, while customization refers to modifying Odoo’s existing modules or adding new features to meet your specific business needs. AttributeX offers both implementation and customization services, ensuring that your Odoo system is both effectively deployed and tailored to your unique requirements.`
    },
    {
      title: "How do I get started with Odoo implementation through AttributeX?",
      content: `Getting started is simple! Contact us through our website or give us a call to schedule an initial consultation. We’ll discuss your business needs, provide a tailored solution, and outline the steps for successful Odoo implementation.`
    }
  ];


  currentUrl: any;


  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _changeDetectorRef: ChangeDetectorRef,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();
  }

  updateMetaTags() {
    const routeWithoutFirstCharacter = this.router.url;
    const currentRoute = routeWithoutFirstCharacter.split('/').pop();

    if (currentRoute == 'implementation') {
      this.titleService.setTitle('AttributeX: Reliable Odoo Implementation Partner in USA');
      this.meta.updateTag({ name: 'description', content: "AttributeX is a trusted Odoo implementation partner in the USA, dedicated to helping businesses of all sizes successfully deploy and leverage the power of Odoo. Contact us today to achieve your business objectives with a customized Odoo solution." });
      this.meta.updateTag({ name: 'title', content: 'AttributeX: Reliable Odoo Implementation Partner in USA' });
      this.meta.updateTag({ name: 'keywords', content: 'odoo implementation' });
    }

    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  openModal() {
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }
}
