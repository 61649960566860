<app-slider></app-slider>
<app-services-cards [services]="services"></app-services-cards>
<app-odoo-services></app-odoo-services>
<app-services-tiles [anaplanServices]="anaplanServices"></app-services-tiles>

<!-- <div class="second-section">
    <div class="container">
        <div class="heading mb-50">Services</div>
        <div class="row m-0">
            <div class="col-md-3 pb-3 pb-md-0">
                <div class="custom_box">
                    <div class="custom_img">
                        <img src="assets/images/services_img1.webp" alt="Bridge Data" width="100%">
                    </div>
                    <div class="custom_heading">Consulting</div>
                    <div class="description"
                        title="Offering expert advisory services in strategic planning and process optimization, leveraging advanced data analytics, cloud solutions, and financial planning platforms to enhance decision-making and operational efficiency.">
                        Offering expert advisory services
                        in strategic planning and process
                        optimization, leveraging advanced data analytics, cloud solutions, and financial planning
                        platforms to enhance decision-making and operational efficiency.
                    </div>
                    <a href="#" class="read_more">Read more</a>
                </div>
            </div>
            <div class="col-md-3 pb-3 pb-md-0">
                <div class="custom_box">
                    <div class="custom_img">
                        <img src="assets/images/services_img2.webp" alt="Stream line" width="100%">
                    </div>
                    <div class="custom_heading">Technology</div>
                    <div class="description"
                        title="Specializing in technology services focused on business intelligence, cloud computing, and financial management systems to empower digital transformation and elevate business processes.">
                        Specializing in technology services focused on business intelligence, cloud
                        computing, and financial management systems to empower digital transformation and elevate
                        business processes.
                    </div>
                    <a href="#" class="read_more">Read more</a>
                </div>
            </div>
            <div class="col-md-3 pb-3 pb-md-0">
                <div class="custom_box">
                    <div class="custom_img">
                        <img src="assets/images/services_img3.webp" alt="Make decisions" width="100%">
                    </div>
                    <div class="custom_heading">Managed Services</div>
                    <div class="description"
                        title="Providing comprehensive managed services to ensure optimal performance and seamless operation of your cloud infrastructure and enterprise software systems, driving efficiency and stability in business operations.">
                        Providing comprehensive managed services to
                        ensure optimal performance and
                        seamless operation of your cloud infrastructure and enterprise software systems, driving
                        efficiency and stability in business operations.
                    </div>
                    <a href="#" class="read_more">Read more</a>
                </div>
            </div>
            <div class="col-md-3 pb-3 pb-md-0">
                <div class="custom_box">
                    <div class="custom_img">
                        <img src="assets/images/services_img4.webp" alt="Make decisions" width="100%">
                    </div>
                    <div class="custom_heading">Staffing</div>
                    <div class="description"
                        title="Delivering tailored staffing solutions with an emphasis on professionals proficient in cutting-edge data analysis, cloud technologies, and enterprise resource planning, meeting the dynamic needs of your projects and organizational goals.">
                        Delivering tailored staffing
                        solutions with an emphasis on professionals
                        proficient in cutting-edge data analysis, cloud technologies, and enterprise resource planning,
                        meeting the dynamic needs of your projects and organizational goals.
                    </div>
                    <a href="#" class="read_more">Read more</a>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="third-section">
    <div class="container px-4">
        <div class="heading">{{anaplanServices.heading}}</div>
        <div class="description">{{anaplanServices.description}}</div>
        <div class="row m-0">
            <div class="col" *ngFor="let service of anaplanServices?.servicesData">
                <figure>
                    <img [src]="service?.imageUrl" [alt]="service?.title">
                    <figcaption class="planning_text">{{ service?.title }}</figcaption>
                </figure>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="third-section">
    <div class="container px-4">
        <div class="heading">Our Anaplan Services</div>
        <div class="description">Transforming enterprise planning and performance with bespoke Anaplan solutions,
            tailored
            to streamline your business processes and enhance decision-making capabilities. </div>
        <div class="row m-0">
            <div class="col">
                <figure>
                    <img src="assets/images/implementation.webp" alt="Implementation and Integration">
                    <figcaption class="planning_text">Implementation and Integration</figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/modal-building.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Model Building and Optimization </figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/financial_analysis.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Financial Planning and Analysis (FP&A)</figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/sales_performanace.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Sales Performance Management (SPM)</figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/scenario_planning.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Scenario Planning and Analysis</figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/training_support.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Training and Support</figcaption>
                </figure>
            </div>
            <div class="col">
                <figure>
                    <img src="assets/images/custom_board.webp" alt="Financial Analysis">
                    <figcaption class="planning_text">Custom Dashboard and Reporting</figcaption>
                </figure>
            </div>
        </div>
    </div>
</div> -->

<div class="fourth-section">
    <div class="container">
        <div class="row m-0">
            <div class="col-md-6 pb-3 pb-md-0">
                <div class="content_section">
                    <div class="description">The platform for financial reporting, ESG, audit, and risk.</div>
                    <div class="highlight_text">And the only one to unite them all.</div>

                    <div class="btn_section">
                        <button class="demo_btn" (click)="openModal()">Get A Demo</button>
                        <!-- <a href="#" routerLink="workiva">EXPLORE THE PLATFORM</a> -->
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div>
                    <div class="text-center mb-2">
                        <img src="assets/images/workiva.webp" alt="Workiva Logo" class="pointer home_workiva_section" routerLink="/workiva">
                    </div>

                    <iframe allowtransparency="true" title="Wistia video player" allowFullscreen frameborder="0"
                        scrolling="no" class="wistia_embed" name="wistia_embed"
                        src="https://fast.wistia.net/embed/iframe/ty3ii2l6df" width="529" height="300"></iframe>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="fourth-section fifth-section">
    <div class="container">
        <div class="row m-0">
            <div class="col-md-7 pb-3 pb-md-0">
                <div class="content_section">
                    <div class="highlight_text">Confidence in an uncertain world</div>
                    <div class="description">Transform how you see, plan and lead your business with our Connected
                        Planning Platform</div>

                    <div class="btn_section">
                        <button class="primary_btn" routerLink="/anaplan">See clearly and adapt quickly</button>
                        <button class="primary_btn secondary_btn" (click)="openModal()">See Anaplan in action</button>
                    </div>
                </div>
            </div>

            <div class="col-md-5">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <img src="assets/images/anaplan_img.webp" width="100%" alt="anaplan">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="second-section sixth-section">
    <div class="container">
        <div class="heading mb-50">Blogs</div>
        <div class="row_cards">

            <div class="custom_box">
                <div class="custom_img">
                    <img src="assets/images/blog1.png" alt="Bridge Data" width="100%">
                </div>
                <div class="blog_heading_section">
                    <div>Blogs</div>
                </div>
                <div class="description">Unlock the secrets of your data lineage through connected data. Don’t rely
                    on
                    blind trust; explore its path across your entire range of insurance products and annuities to
                    understand its journey firsthand.
                </div>

                <div class="article_link">
                    <a href="#">Open Article <span class="material-symbols-outlined">east</span>
                    </a>
                </div>
            </div>

            <div class="custom_box">
                <div class="custom_img">
                    <img src="assets/images/blog2.png" alt="Stream line" width="100%">
                </div>

                <div class="blog_heading_section">
                    <div>Blogs</div>
                </div>
                <div class="description">Empower teams by automating routine tasks, whether it’s aggregating data,
                    testing controls, or updating language across various accounts. Free yourself to focus on tasks
                    where your unique expertise truly matters.
                </div>

                <div class="article_link">
                    <a href="#">Open Article <span class="material-symbols-outlined">east</span>
                    </a>
                </div>
            </div>

            <div class="custom_box">
                <div class="custom_img">
                    <img src="assets/images/blog3.png" alt="Make decisions" width="100%">
                </div>
                <div class="blog_heading_section">
                    <div>Blogs</div>
                </div>
                <div class="description">Enhance data visibility, minimize tedious tasks, and channel your efforts
                    into crafting strategic recommendations that propel you and your team to new heights.
                </div>

                <div class="article_link">
                    <a href="#">Open Article <span class="material-symbols-outlined">east</span>
                    </a>
                </div>
            </div>
            <div class="custom_box">
                <div class="custom_img">
                    <img src="assets/images/blog3.png" alt="Make decisions" width="100%">
                </div>
                <div class="blog_heading_section">
                    <div>Blogs</div>
                </div>
                <div class="description">Enhance data visibility, minimize tedious tasks, and channel your efforts
                    into crafting strategic recommendations that propel you and your team to new heights.
                </div>

                <div class="article_link">
                    <a href="#">Open Article <span class="material-symbols-outlined">east</span>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div> -->


<!-- <div class="fourth-section seventh-section">
    <div class="container">
        <div class="row m-0">
            <div class="col-md-6">
                <div class="content_section">
                    <div class="highlight_text">Frequently asked Questions</div>
                    <div>Can’t find an answer?</div>

                    <div class="btn_section">
                        <button class="demo_btn" (click)="openModal()">Contact us for
                            more information</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="qtn_container">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Are you seeking a solution to streamline the end-to-end reporting process, from data
                                    collection
                                    to
                                    distribution?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Workiva’s automation and data consolidation capabilities can significantly reduce
                                    the time spent on manual data entry and minimize the risk of errors in your reports.
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Are you concerned about data security and compliance with ever-changing regulations?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Quisque sapien augue, ornare id leo a, tristique elementum justo. Praesent non nulla
                                    sagittis, sollicitudin justo id, varius erat. Nunc sed pharetra nisl. Cras et
                                    suscipit
                                    felis, in lacinia sapien. Integer venenatis sagittis massa, eu eleifend nibh
                                    venenatis
                                    in. Pellentesque a aliquet urna. Curabitur tortor metus, ultrices sed mi at,
                                    sagittis
                                    imperdiet turpis. Suspendisse nec luctus nunc. Fusce in arcu quis lacus mollis
                                    ultrices.
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Is real-time collaboration among your team members and stakeholders a challenge?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Praesent nec ipsum scelerisque dui condimentum pellentesque eu at lectus. Vivamus
                                    purus
                                    purus, bibendum in vestibulum ac, pharetra sit amet sapien. Nunc luctus, orci vel
                                    luctus
                                    cursus, nibh nisl ullamcorper ipsum, eu malesuada arcu augue id nisi. In auctor mi
                                    ac
                                    ante tincidunt tincidunt.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->