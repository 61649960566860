import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit, ViewChild } from '@angular/core';
// import { MenuService } from './menu.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NavItem } from './menu.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-mega-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, MatMenuModule, MatIconModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './mega-menu.component.html',
  styleUrl: './mega-menu.component.scss'
})
export class MegaMenuComponent implements OnInit {
  @Input() items: NavItem[];
  @ViewChild('childMenu') public childMenu: any;

  constructor(public router: Router) {
    this.items = []
  }

  ngOnInit() {
  }

  collapseHide(event: any) {
    const removeClass = document.getElementById('collapsibleNavbar');
    // if (event.children) {
      // console.log(removeClass);
      
      removeClass?.classList.remove('show');
    // }
  }
}
