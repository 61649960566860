import { ChangeDetectorRef, Component, Renderer2, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { NavItem } from '../mega-menu/menu.service';
import { Router, RouterModule } from '@angular/router';
import { MegaMenuComponent } from '../mega-menu/mega-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from '../../services/api.service';
import { AxiosResponse } from 'axios';
import { MatSidenavModule } from '@angular/material/sidenav';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, NgbModule, RouterModule, MatSidenavModule, MegaMenuComponent, MatMenuModule, MatButtonModule, MatIconModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  showFiller = false;
  navItems: NavItem[] = [];
  @ViewChild('collapsibleNavbar') collapsibleNavbar!: ElementRef;
  getServiceData: any;
  menuItems: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiService,
  ) {

    this.menuItems = [
      {
        label: 'Home',
        route: '/',
      },
      {
        label: 'Services',
        more: true,
        children: [
          {
            label: 'Managed Services',
            route: '/managed-services'
          },
          {
            label: 'Staffing',
            route: '/staffing'
          },
          {
            label: 'Consulting',
            // route: '/consulting',
            more: true,
            subItems: [
              {
                label: 'What we do?',
                route: '/consulting'
              },
              {
                label: 'Cloud',
                route: '/consulting/cloud'
              },
              {
                label: 'Cloud Migration',
                route: '/consulting/cloud-migration'
              },
            ]
          },
        ]
      },
      {
        label: 'Technologies',
        // route: '/technology',
        more: true,
        children: [
          {
            label: 'What we do?',
            route: '/technology',
          },
          {
            label: 'Anaplan',
            route: '/anaplan'
          },
          {
            label: 'Workiva',
            route: '/workiva'
          },
          {
            label: 'Odoo',
            // logo: 'assets/images/odoo/odoo_ready_partners_rgb.svg',
            // route: '/odoo',
            more: true,
            subItems: [
              {
                label: 'Odoo Services',
                route: '/odoo/odoo-services'
              },
              {
                label: 'Odoo customization',
                route: 'odoo/customization'
              },
              {
                label: 'Odoo Implementation',
                route: 'odoo/implementation'
              },
              // {
              //   label: 'Odoo Integration',
              //   route: 'odoo/integration'
              // },
            ]
          },
        ]
      },

    ];

  }

  ngOnInit() {
    // this.onLoadData();
    this.cdr.detectChanges();
  }

  onLoadData() {
    this.navItems.map((element: any) => {
      console.log(element);

    })
    return
    this.apiService.get('/service/service_dropdown_list').subscribe((response: AxiosResponse<unknown>) => {
      if (response?.status) {
        this.getServiceData = response?.data;
        console.log('Service data:', this.getServiceData?.data);

      }
    },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  openModal() {
    this.collapsibleNavbar.nativeElement?.classList.remove('show');
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
    // You can pass data to the modal using modalRef.componentInstance
    // For example:
    // modalRef.componentInstance.data = yourData;
  }

  subItemsNavigate(route: any) {
    this.collapsibleNavbar.nativeElement?.classList.remove('show');
    this.router.navigateByUrl(route);

  }

  closeModal() {
    this.modalService.dismissAll();
  }

  collapseHide(event: any) {
    if (!event) {
      this.collapsibleNavbar.nativeElement?.classList.remove('show');
    }
  }
}
