import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import { SliderComponent } from '../slider/slider.component';
import { ServicesCardsComponent } from '../services-cards/services-cards.component';
import { ServicesTilesComponent } from '../services-tiles/services-tiles.component';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-workiva',
  standalone: true,
  imports: [RouterModule, CommonModule, SliderComponent, ServicesCardsComponent, ServicesTilesComponent],
  templateUrl: './workiva.component.html',
  styleUrl: './workiva.component.scss',
})
export class WorkivaComponent {
  getData: any;
  isData: boolean = false;
  currentRoute: any;
  currentUrl: any;


  services = {
    heading: 'Services',
    cards: [
      {
        imageUrl: 'assets/images/services_img3.webp',
        title: 'Managed Services',
        route: '/managed-services',
        description: 'Providing comprehensive managed services to ensure optimal performance and seamless operation of your cloud infrastructure and enterprise software systems, driving efficiency and stability in business operations.'
      },
      {
        imageUrl: 'assets/images/services_img2.webp',
        title: 'Technology',
        route: '/technology',
        description: 'Specializing in technology services focused on business intelligence, cloud computing, and financial management systems to empower digital transformation and elevate business processes.'
      },
      {
        imageUrl: 'assets/images/services_img1.webp',
        title: 'Consulting',
        route: '/consulting',
        description: 'Offering expert advisory services in strategic planning and process optimization, leveraging advanced data analytics, cloud solutions, and financial planning platforms to enhance decision-making and operational efficiency.'
      },
      {
        imageUrl: 'assets/images/services_img4.webp',
        title: 'Staffing',
        route: '/staffing',
        description: 'Delivering tailored staffing solutions with an emphasis on professionals proficient in cutting-edge data analysis, cloud technologies, and enterprise resource planning, meeting the dynamic needs of your projects and organizational goals.'
      }
    ]
  }


  workivaServices = {
    heading: 'Our Workiva Services',
    route: '/workiva',
    description: 'Transforming enterprise planning and performance with bespoke Anaplan solutions, tailored to streamline your business processes and enhance decision-making capabilities.',
    servicesData: [
      { imageUrl: 'assets/images/implementation.webp', title: 'Implementation and Integration' },
      { imageUrl: 'assets/images/modal-building.webp', title: 'Model Building and Optimization' },
      { imageUrl: 'assets/images/financial_analysis.webp', title: 'Financial Planning and Analysis (FP&A)' },
      { imageUrl: 'assets/images/sales_performanace.webp', title: 'Sales Performance Management (SPM)' },
      { imageUrl: 'assets/images/scenario_planning.webp', title: 'Scenario Planning and Analysis' },
      { imageUrl: 'assets/images/training_support.webp', title: 'Training and Support' },
      { imageUrl: 'assets/images/custom_board.webp', title: 'Custom Dashboard and Reporting' }
    ]
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private apiService: ApiService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        // const currentUrl = window.location.origin + this.route.snapshot.url.join('/');
        this.canonicalService.setCanonicalUrl(this.currentUrl);
    });
    }
    // console.log(this.currentUrl);
    this.titleService.setTitle(' AttributeX | Workiva Financial Statement Automation');

    const routeWithoutFirstCharacter = this._router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();
    this.fetchData();
    this._changeDetectorRef.detectChanges();
  }

  fetchData() {
    this.apiService.get<any>(`/blog/list?page_route=${this.currentRoute}&is_site=1`).subscribe((response) => {
      if (response) {
        this.getData = response?.data?.data;
        this.getData?.forEach((element: any) => {
          if (element.service_id == '2') {
            this.isData = true;
          }
        });
        // console.log(this.getData);
        this._changeDetectorRef.detectChanges();
      }
    });

    if (this.currentRoute = 'workiva') {
      this.meta.updateTag({ name: 'title', content: 'AttributeX | Workiva Financial Statement Automation' });
      this.meta.updateTag({ name: 'description', content: 'AttributeX is a global consulting firm specialized in workiva financial statement automation and providing comprehensive operational enhancements and technology solutions.' });
      this.meta.updateTag({ name: 'keywords', content: 'workiva Financial Statement Automation' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });

      this._changeDetectorRef.detectChanges();
    }
  }

  blogDetails(id: any) {
    this._router.navigate(['/blogs', id]);
  }
}
