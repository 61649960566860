<div class="position-relative">
    <img src="assets/images/odoo/Implementation.webp" width="100%" alt="implementation">
    <button class="learn_more_btn" (click)="openModal()">Learn More</button>
</div>

<div class="text-center second_section">
    <h1 class="heading">AttributeX: Your Reliable Odoo Implementation Partner in the USA</h1>
    <div class="description description_2">When it comes to implementing Odoo, you need a partner who understands your
        business inside out. AttributeX is a trusted Odoo implementation partner in the USA, dedicated to helping
        businesses of all sizes successfully deploy and leverage the power of Odoo's comprehensive ERP system.
    </div>
</div>

<app-odoo-services [odooPartner]="odooPartner"
    [heading]="'Why Choose AttributeX for Odoo Implementation?'"></app-odoo-services>


<div class="text-center second_section">
    <h2 class="heading mb-0">Experience the AttributeX Difference</h2>
    <div class="description ">At AttributeX, we pride ourselves on delivering successful Odoo implementations that drive
        real business results. Our client-focused approach ensures that your Odoo system is not just another software
        tool, but a powerful asset that enhances efficiency, reduces costs, and supports your growth.
    </div>
    <div class="description sub_description">Ready to transform your business with Odoo? Contact AttributeX today to
        learn more about our implementation services and how we can help you achieve your business objectives with a
        customized Odoo solution.</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Get in Touch</button>
    </div>
</div>

<app-odoo-services [odooPartner]="implementationProcess"
    [heading]="'Our Odoo Implementation Process'"></app-odoo-services>

<!-- <div>
    <img src="assets/images/odoo/Implementation-process.webp" width="100%" alt="implementation">
</div> -->

<hr class="mt-0 mb-5">

<app-faq [heading]="'Odoo Implementation with AttributeX (FAQs)'" [accordionData]="accordionData"></app-faq>