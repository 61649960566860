import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog-image-slider',
  standalone: true,
  imports: [CommonModule, RouterModule, NgbModule],
  templateUrl: './blog-image-slider.component.html',
  styleUrl: './blog-image-slider.component.scss'
})
export class BlogImageSliderComponent {
  @Input() data: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    // console.log(this.data);

    // if (this.data?.image == 'IMAGE1') {
    //   document.getElementById('image1')?.classList.add('active');
    // } else if (this.data?.image == 'IMAGE2') {
    //   document.getElementById('image2')?.classList.add('active');
    // } else if (this.data?.image == 'IMAGE3') {
    //   document.getElementById('image3')?.classList.add('active');
    // }

  }

  get imageList(): string[] {
    const images: any = [];
    if (this.data?.data?.image1) {
      images.push(this.data?.data?.image1);
    }
    if (this.data?.data?.image2) {
      images.push(this.data?.data?.image2);
    }
    if (this.data?.data?.image3) {
      images.push(this.data?.data?.image3);
    }
    return images;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
