<video #videoPlayer width="100%" controls autoplay>
    <source src="https://download.odoocdn.com/videos/odoo_com/video_homepage.webm" type="video/webm">
</video>

<div class="text-center second_section">
    <h1 class="heading">AttributeX: Your Trusted Odoo Partner in the USA</h1>
    <div class="description">Welcome to AttributeX, your go-to Odoo partner. As a Odoo Partner, we specialize in
        providing comprehensive
        Odoo solutions tailored to meet the unique needs of your business.
    </div>
    <button class="custom_btn" (click)="openModal()">Book a Free ERP Consultation</button>
</div>

<app-odoo-services></app-odoo-services>

<!-- <div class="why_choose_section">
    <div class="heading">Why Choose AttributeX As Your Odoo Partner?</div>

    <div class="row mt-5">
        <div class="col-md-6 pb-4" *ngFor="let card of odooPartner">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon">
                <div class="services_card mt-3">
                    <div class="title mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button class="custom_btn mt-4" (click)="openModal()">Let’s solve your business challenges together!</button>

</div> -->


<div class="case_studies_section">
    <div class="heading text-center mb-5">Case Studies</div>

    <!-- <div class="blue_card mb-4">
        <div class="row m-0">
            <div class="col-6">
                <img src="assets/images/odoo/case_study_1.svg" width="100%" alt="case_study_1">
            </div>
            <div class="col-6">
               <div class="heading">Odoo Helps MPF Consultancy GUM Save 50% Time On HR Tasks</div>
               <div class="content">Apps Implemented: Attendances, Surveys, Project, Email Marketing, eLearning, Live Chat, Helpdesk, Employee Contracts, Payroll, Appraisals, Forum, Website, Blogs, Documents, Studio, Accounting, Sales, Invoicing, Discuss, Contacts, Calendar, Dashboards, Employees, Consolidation</div>
               <div class="d-flex align-items-center">
                   <div class="me-1">Tags: </div>
                   <ul class="list-unstyled d-flex m-0">
                    <li>APSC</li>
                    <li>ASIA</li>
                    <li>financial services</li>
                    <li>mid sized organisation</li>
                    <li>odoo.sh</li>
                    <li>services</li>
                   </ul>
                </div>

                <div class="mt-4 pt-4">
                    <button class="custom_btn d-flex align-items-center">Read the Case Study <span class="material-symbols-outlined ms-2">east</span></button>
                </div>

            </div>
        </div>
    </div> -->
    <div *ngFor="let study of caseStudies" class="blue_card mb-4">
        <div class="row m-0">
            <div class="col-lg-6">
                <img [src]="study.imgSrc" width="100%" alt="case_study">
            </div>
            <div class="col-lg-6">
                <div class="heading">{{ study.heading }}</div>
                <div class="content">{{ study.content }}</div>
                <div class="d-flex align-items-center flex-column flex-lg-row">
                    <div class="me-1">Tags: </div>
                    <ul class="list-unstyled d-flex m-0 mt-3 mt-lg-0">
                        <li *ngFor="let tag of study.tags">{{ tag }}</li>
                    </ul>
                </div>
                <div class="mt-4 pt-4">
                    <button class="custom_btn d-flex align-items-center read_btn" (click)="caseStudy(study?.url)">Read
                        the Case Study <span class="material-symbols-outlined ms-2">east</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>







<div class="text-center second_section">
    <div class="heading">Take the Next Step with AttributeX</div>
    <div class="description ">Ready to transform your business with Odoo? Contact us today to schedule a personalized
        demo or consultation. Our certified Odoo consultants are here to show you how our tailored solutions can meet
        your unique business needs.
    </div>
    <div class="description">Get in touch now and discover the power of Odoo with AttributeX!</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Schedule a Consultation</button>
        <button class="custom_btn outline_btn" (click)="openModal()">Request a Demo</button>
    </div>
</div>