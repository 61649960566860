import { ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { SliderComponent } from '../slider/slider.component';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-anaplan',
  standalone: true,
  imports: [RouterModule, CommonModule, MatTabsModule, SliderComponent],
  templateUrl: './anaplan.component.html',
  styleUrl: './anaplan.component.scss',
})
export class AnaplanComponent {
  getData: any;
  isData: boolean = false;
  currentRoute: any;
  tabs = [
    // {
    //   tabName: 'Anaplan Services',
    // },
    {
      tabName: 'Financial Planning and Analysis (FP&A)',
      tabContent: [
        {
          title: 'Strategic Financial Planning',
          Content: 'Leverage Anaplan for sophisticated financial planning that aligns with your strategic goals. We aid in creating comprehensive financial models that encompass budgeting,forecasting, and long-term planning.',
        },
        {
          title: 'Dynamic Forecasting and Budgeting',
          Content: 'Utilize Anaplan for dynamic and real-time budgeting and forecasting. Adapt quickly to market changes with rolling forecasts and what-if scenarios.',
        },
        {
          title: 'Cost and Profitability Analysis',
          Content: 'Our solutions provide in-depth insights into cost management and profitability analysis, helping you identify areas for cost reduction and profit maximization.',
        },
        {
          title: 'Financial Consolidation and Reporting',
          Content: 'With Anaplan, consolidate financial data from various sources for accurate reporting. We ensure you get a unified view of your financial performance across the organization.',
        },
      ]
    },
    {
      tabName: 'Sales Performance Management (SPM)',
      tabContent: [
        {
          title: 'Comprehensive Sales Planning',
          subTitle: 'Territory Optimization',
          Content: 'With Anaplan, we optimize sales territories to balance workload, maximize market coverage, and reduce travel time. Our solutions ensure territories are aligned with market potential and sales capacity.',
        },
        {
          title: '',
          subTitle: 'Quota Setting and Allocation',
          Content: 'We assist in setting and allocating quotas that are ambitious yet achievable, based on a deep analysis of market opportunities, historical performance, and sales capacity.',
        },
        {
          title: 'Incentive Compensation Management',
          Content: 'Design effective incentive programs with Anaplan to motivate your sales team. We develop compensation plans that are fair, transparent, and directly linked to individual and team performance.',
        },
        {
          title: 'Real-time Sales Forecasting and Analysis',
          Content: 'Anaplan’s real-time data capabilities enable accurate sales forecasting. Analyze sales trends, track performance, and adjust strategies promptly to meet targets.',
        },
        {
          title: 'Sales Pipeline and Opportunity Management',
          Content: 'Effectively manage your sales pipeline with Anaplan, tracking opportunities, assessing pipeline health, and forecasting future sales.',
        },
        {
          title: 'Performance Dashboards and Analytics',
          Content: 'Create custom dashboards and analytics within Anaplan to provide actionable insights. Monitor key sales metrics and performance indicators to inform strategic decisions.',
        },
        {
          title: 'Collaboration Across Departments',
          Content: 'Ensure alignment between sales, marketing, finance, and operations with Anaplan’s collaborative features, facilitating cross-functional planning and communication.',
        },
        {
          title: 'Adaptable Sales Strategies',
          Content: 'Model various sales scenarios with Anaplan to prepare for and swiftly respond to market changes, keeping your sales strategies agile and effective.',
        },
        // {
        //   title: 'Scenario Planning and Analysis',
        //   Content: 'Prepare for the future with Anaplan&#39;s robust scenario planning tools. We help you create and analyze multiple scenarios to strategize effectively for upcoming market trends and business risks.',
        // },
        // {
        //   title: 'Training and Support',
        //   Content: 'Empower your team with comprehensive training on the Anaplan platform. Our support extends beyond implementation - we provide ongoing assistance to ensure you continuously reap the benefits of Anaplan.',
        // },
        // {
        //   title: 'Custom Dashboard and Reporting',
        //   Content: 'Get tailored dashboards and reports that provide actionable insights. Our custom-designed reporting tools help you monitor key performance indicators and make data-driven decisions.',
        // },
      ]
    }
  ];
  selected = new FormControl(0);
  tabtitle: string = '';
  currentUrl: any;
  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private apiService: ApiService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.currentUrl = window.location.href;

      this.route.url.subscribe(urlSegments => {
        // const currentUrl = window.location.origin + this.route.snapshot.url.join('/');
        this.canonicalService.setCanonicalUrl(this.currentUrl);
    });
    }


    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateMetaTags();
      }
    });

    this.updateMetaTags();

    this.fetchData();
  }

  updateMetaTags() {  
    // Logic to update meta tags based on the current route
    const routeWithoutFirstCharacter = this._router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();;
    
      this.titleService.setTitle('Enhancing Business Performance & Strategic Planning with Anaplan | AttributeX');
      this.meta.updateTag({ name: 'description', content: "Revolutionize your finance, supply chain, and sales strategies with AttributeX's Anaplan services, the cornerstone of connected planning. Our expertise propels business performance by integrating cross-functional data for real-time insights and collaborative decision-making. Partner with us to harness the power of connected planning, driving unparalleled growth and operational efficiency." });
      this.meta.updateTag({ name: 'title', content: 'Enhancing Business Performance & Strategic Planning with Anaplan | AttributeX' });
      this.meta.updateTag({ name: 'keywords', content: 'Anaplan Business Performance and Planning' });
      // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });
      this._changeDetectorRef.detectChanges();
 
    // Trigger change detection to update the view
    this._changeDetectorRef.detectChanges();
  }

  fetchData() {
    this.apiService.get<any>(`/blog/list?page_route=${this.currentRoute}&is_site=1`).subscribe((response) => {
      if (response) {
        this.getData = response?.data?.data;
        this.getData?.forEach((element: { service_id: string; }) => {
          if (element.service_id == '1') {
            this.isData = true;
          }
        });
        // console.log(this.getData);
        this._changeDetectorRef.detectChanges();
      }
    });
  }

  blogDetails(id: any) {
    this._router.navigate(['/blogs', id]);
  }



  openModal() {
    const modalRef = this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
    // You can pass data to the modal using modalRef.componentInstance
    // For example:
    // modalRef.componentInstance.data = yourData;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
