import { CommonModule } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../contact-us/contact-us.component';

@Component({
  selector: 'app-odoo-services',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './odoo-services.component.html',
  styleUrl: './odoo-services.component.scss'
})
export class OdooServicesComponent {
  @Input() heading: string = 'Why Choose AttributeX As Your Odoo Partner?';
  @Input() buttonText: string = 'Let’s solve your business challenges together!';

  @Input() odooPartner: any[] = [
    {
      icon: "assets/images/odoo/consultants.svg",
      service_name: "Certified Odoo Consultants",
      description: " Our team consists of highly experienced Odoo consultants who are certified and well-versed in delivering reliable Odoo consultation. We guide you through every step of your Odoo journey, ensuring smooth and efficient implementation."
    },
    {
      icon: "assets/images/odoo/studio.svg",
      service_name: "Expertise in Odoo Studio",
      description: "Whether you're looking to customize your Odoo environment or develop new functionalities, our experts excel in using Odoo Studio to create bespoke solutions that enhance your business processes."
    },
    {
      icon: "assets/images/odoo/development.svg",
      service_name: "Odoo Implementation and Development",
      description: "At AttributeX, we take pride in our robust Odoo implementation services. Our skilled developers work diligently to integrate and develop Odoo applications that align with your business objectives, providing seamless and efficient user experience."
    },
    {
      icon: "assets/images/odoo/integration.svg",
      service_name: "Comprehensive Odoo Integration",
      description: "We offer Odoo integration services to ensure your systems work in harmony. Our team integrates Odoo with various third-party applications and platforms, streamlining your operations and improving productivity."
    },
    {
      icon: "assets/images/odoo/migration.svg",
      service_name: "Odoo customization and Migration",
      description: "Customize your Odoo ERP to fit your business needs. We provide Odoo customization services to modify the existing features or add new ones. Additionally, our Odoo migration services help you seamlessly transition from legacy systems or older versions of Odoo to the latest release, minimizing downtime and ensuring data integrity."
    },
    {
      icon: "assets/images/odoo/support.svg",
      service_name: "Ongoing Odoo Support",
      description: "Our commitment to your success doesn't end with implementation. We provide continuous Odoo support and maintenance to keep your system running smoothly, addressing any issues promptly and efficiently."
    },
  ];


  // odooPartner:any = [
  //   {
  //     icon: "assets/images/odoo/consultants.svg",
  //     service_name: "Certified Odoo Consultants",
  //     description: " Our team consists of highly experienced Odoo consultants who are certified and well-versed in delivering reliable Odoo consultation. We guide you through every step of your Odoo journey, ensuring smooth and efficient implementation."
  //   },
  //   {
  //     icon: "assets/images/odoo/studio.svg",
  //     service_name: "Expertise in Odoo Studio",
  //     description: "Whether you're looking to customize your Odoo environment or develop new functionalities, our experts excel in using Odoo Studio to create bespoke solutions that enhance your business processes."
  //   },
  //   {
  //     icon: "assets/images/odoo/development.svg",
  //     service_name: "Odoo Implementation and Development",
  //     description: "At AttributeX, we take pride in our robust Odoo implementation services. Our skilled developers work diligently to integrate and develop Odoo applications that align with your business objectives, providing seamless and efficient user experience."
  //   },
  //   {
  //     icon: "assets/images/odoo/integration.svg",
  //     service_name: "Comprehensive Odoo Integration",
  //     description: "We offer Odoo integration services to ensure your systems work in harmony. Our team integrates Odoo with various third-party applications and platforms, streamlining your operations and improving productivity."
  //   },
  //   {
  //     icon: "assets/images/odoo/migration.svg",
  //     service_name: "Odoo customization and Migration",
  //     description: "Customize your Odoo ERP to fit your business needs. We provide Odoo customization services to modify the existing features or add new ones. Additionally, our Odoo migration services help you seamlessly transition from legacy systems or older versions of Odoo to the latest release, minimizing downtime and ensuring data integrity."
  //   },
  //   {
  //     icon: "assets/images/odoo/support.svg",
  //     service_name: "Ongoing Odoo Support",
  //     description: "Our commitment to your success doesn't end with implementation. We provide continuous Odoo support and maintenance to keep your system running smoothly, addressing any issues promptly and efficiently."
  //   },
  // ]
  currentRoute: any;

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router
  ) {
    const routeWithoutFirstCharacter = this.router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();
  }

  openModal() {
    if (this.currentRoute == '') {
      this.router.navigateByUrl('odoo/odoo-services');
    } else {
      this.modalService.open(ContactUsComponent, {
        windowClass: 'custom-modal-lg',
      });
    }
  }
}
