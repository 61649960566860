<div class="container">
    <div class="section-title d-flex align-items-center justify-content-between">
        <h3 class="fw-bold">Get in Touch</h3>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <div class="contact">
                <form class="form" [formGroup]="formData" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <input type="text" formControlName="full_name" class="form-control" placeholder="Name"
                                [ngClass]="{ 'is-invalid': formData.get('full_name')?.invalid && formData.get('full_name')?.touched }">
                            <div *ngIf="formData.get('full_name')?.invalid && formData.get('full_name')?.touched">
                                <small *ngIf="formData.get('full_name')?.errors?.['required']" class="text-danger">
                                    Name is required.
                                </small>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <input type="email" formControlName="email" class="form-control" placeholder="Work Email"
                                [ngClass]="{ 'is-invalid': formData.get('email')?.invalid && formData.get('email')?.touched }">
                            <div *ngIf="formData.get('email')?.invalid && formData.get('email')?.touched">
                                <small *ngIf="formData.get('email')?.errors?.['required']" class="text-danger">
                                    Work Email is required.
                                </small>
                                <small *ngIf="formData.get('email')?.errors?.['pattern']" class="text-danger">
                                    Please enter a valid email address.
                                </small>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <input type="text" formControlName="phone_number" class="form-control" maxlength="10"
                                placeholder="Phone number"
                                [ngClass]="{ 'is-invalid': formData.get('phone_number')?.invalid && formData.get('phone_number')?.touched }">
                            <div *ngIf="formData.get('phone_number')?.invalid && formData.get('phone_number')?.touched">
                                <small *ngIf="formData.get('phone_number')?.errors?.['required']" class="text-danger">
                                    Phone Number is required.
                                </small>
                                <small *ngIf="formData.get('phone_number')?.errors?.['pattern']" class="text-danger">
                                    Please enter a valid 10-digit Phone Number..
                                </small>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <input type="text" formControlName="company_name" class="form-control"
                                placeholder="Company Name"
                                [ngClass]="{ 'is-invalid': formData.get('company_name')?.invalid && formData.get('company_name')?.touched }">
                            <div *ngIf="formData.get('company_name')?.invalid && formData.get('company_name')?.touched">
                                <small *ngIf="formData.get('company_name')?.errors?.['required']" class="text-danger">
                                    Company Name is required.
                                </small>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <textarea rows="4" formControlName="comments" class="form-control"
                                placeholder="Your Message"
                                [ngClass]="{ 'is-invalid': formData.get('comments')?.invalid && formData.get('comments')?.touched }"></textarea>
                            <div *ngIf="formData.get('comments')?.invalid && formData.get('comments')?.touched">
                                <small *ngIf="formData.get('comments')?.errors?.['required']" class="text-danger">
                                    Message is required.
                                </small>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" name="submit" class="btn btn-contact-bg"
                                title="Submit Your Message!">Send
                                Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-5">
            <div class="single_address">
                <i class="fa fa-map-marker"></i>
                <h4>Our Address</h4>
                <p>11040 Bollinger Canyon Rd, E455, San Ramon, California 94582, US</p>
            </div>
            <div class="single_address">
                <i class="fa fa-envelope"></i>
                <h4>Send your message</h4>
                <a href="mailto:info@attributex.com">info&#64;attributex.com</a>
            </div>
            <div class="single_address">
                <i class="fa fa-phone"></i>
                <h4>Call us on</h4>
                <a href="tel:+1(858)-304-8004">+1 (858)-304-8004</a>
            </div>
            <!-- <div class="single_address">
                <i class="fa fa-clock-o"></i>
                <h4>Work Time</h4>
                <p>Mon - Fri: 08.00 - 16.00. <br>Sat: 10.00 - 14.00</p>
            </div> -->
        </div>
    </div>
</div>

<!-- <div class="mt-4">
    <app-captcha (dataEvent)="receiveData($event)"></app-captcha>
</div> -->