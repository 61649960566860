<div class="position-relative">
    <img src="assets/images/odoo/Customisation.webp" width="100%" alt="customization">
    <button class="learn_more_btn" (click)="openModal()">Learn More</button>
</div>

<div class="text-center second_section">
    <h1 class="heading">AttributeX: Your Trusted Odoo Customization Partner in the USA</h1>
    <div class="description description_2">At AttributeX, we are more than just an Odoo partner; we are your go-to
        solution for
        customized ERP systems tailored to meet your unique business needs. As a certified Odoo partner in the USA, we
        specialize in providing top-notch Odoo customization services that empower businesses to optimize their
        operations, enhance productivity, and drive growth.
    </div>
</div>

<app-odoo-services [odooPartner]="odooPartner"
    [heading]="'Why Choose AttributeX for Odoo Customization?'"></app-odoo-services>

<div class="text-center second_section">
    <h2 class="heading mb-0">Transform Your Business with AttributeX and Odoo</h2>
    <div class="description ">Join the growing number of businesses in the USA that have transformed their operations
        with customized Odoo solutions from AttributeX. Let us help you harness the full potential of Odoo to streamline
        your processes, reduce costs, and achieve greater efficiency.
    </div>
    <div class="description sub_description">Ready to customize your Odoo system? Contact us today and let's discuss how
        AttributeX can
        tailor Odoo to meet your business needs.</div>
    <div class="mt-4 pt-3">
        <button class="custom_btn" (click)="openModal()">Get in Touch</button>
    </div>
</div>

<hr class="mt-0 mb-5">

<app-faq [heading]="'Frequently asked Questions (FAQs)'" [accordionData]="accordionData"></app-faq>