import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  setNoIndex() {
    const metaTag = this.document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex, nofollow';
    this.document.head.appendChild(metaTag);
  }
}
