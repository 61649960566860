import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-services-cards',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './services-cards.component.html',
  styleUrl: './services-cards.component.scss'
})
export class ServicesCardsComponent {
  @Input() services: any;
  currentRoute: any;

  constructor(private _router: Router) {
    const routeWithoutFirstCharacter = this._router.url;
    this.currentRoute = routeWithoutFirstCharacter.split('/').pop();

  }
}
