<div class="custom_title">Seamless Cloud Migration Services - Transition with Confidence</div>

<!-- <app-slider></app-slider> -->
<div>
    <img src="assets/images/consulting cloud_migration - cover image.webp" width="100%"
        alt="consulting cloud_migration">
</div>

<div class="content_section">
    <div class="title">Effortlessly Migrate Your Cloud Infrastructure to Unlock New Possibilities</div>
    <div class="line_height_24">Migrating your cloud services from one provider to another, such as AWS to GCP or
        Microsoft Azure to AWS, can open new opportunities for performance, cost savings, and functionality.
        At AttributeX, we specialize in orchestrating seamless cloud migrations that minimize disruption and maximize
        efficiency, ensuring your business continues to thrive throughout the transition.</div>
</div>

<div class="content_section blue_card">
    <div class="title mb-5">Why Migrate Your Cloud Services?</div>
    <div class="row">
        <div class="col-md-6 pb-4" *ngFor="let card of migrateCloudData; let index as index">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon" [alt]="'icon' + (index + 1)">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg_blue_section bg-white">
    <div class="custom_title p-0 mb-5">Our Cloud Migration Services</div>
    <div class="row m-0">
        <div class="col-md-6" *ngFor="let card of migrationServicesData;let index as index">
            <div class=" d-flex gap-3 service_section_card h-100">
                <div>
                    <img [src]="card?.icon" [alt]="'icon' + (index + 1)">
                </div>
                <div class="services_card">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="content_section blue_card">
    <div class="title mb-5">Migration Use Cases</div>
    <div class="row">
        <div class="col-md-4 pb-4" *ngFor="let card of migrationUseCases;let index as index">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon" [alt]="'icon' + (index + 1)">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content_section">
    <div class="title mb-5">Why Choose AttributeX for Your Cloud Migration?</div>
    <div class="row">
        <div class="col-md-4 pb-4" *ngFor="let card of cloudMigrationData;let index as index">
            <div class="blue_card h-100 p-4">
                <img [src]="card?.icon" [alt]="'icon' + (index + 1)">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="content_section blue_card">
    <div class="title mb-5">Our Cloud Migration Services</div>
    <div class="row">
        <div class="col-md-4 pb-4" *ngFor="let card of data">
            <div class="bg-white p-4 h-100">
                <img [src]="card?.icon">
                <div class="services_card mt-3">
                    <div class="heading mb-2">
                        {{card?.service_name}}
                    </div>
                    <div class="content" [title]="card?.description">
                        {{card?.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<hr>
<div class="bg-white contact_main_section">
    <!-- <div class="container-fluid"> -->
    <!-- <div class="contact_section">
            <div class="row">
                <div class="col-md-6">
                    <div class="elevate_text mb-3 mb-md-0">{{cloudContent?.heading}}</div>
                </div>
                <div class="col-md-6 pb-3" *ngIf="cloudContent?.description">
                    <div class="elevate_description mb-3 mb-md-0">
                        {{cloudContent?.description}}</div>
                </div>
                <div class="col-md-6" [ngClass]="cloudContent?.description ? 'text-start':'text-end'">
                    <button class="contact_btn me-md-5 pe-md-5" (click)="openModal()">Contact us for more
                        information</button>
                </div>
            </div>
        </div> -->
    <div class="contact_section">
        <div class="row">
            <div class="col-md-12">
                <div class="elevate_text mb-3 mb-md-2">Begin Your Cloud Migration Journey</div>
            </div>
            <div class="col-md-12 pb-3">
                <div class="elevate_description mb-3">Ready to unlock the full potential of your cloud infrastructure?
                    Contact us today to discuss how our cloud migration services can benefit your business.</div>
            </div>
            <div class="col-md-12">
                <button class="contact_btn" (click)="openModal()">Get in Touch</button>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>