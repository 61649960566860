import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { NavItem } from '../../mega-menu/menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterModule } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, RouterModule, MatIconModule],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent {
  showFiller = false;
  navItems: NavItem[] = [];
  getServiceData: any;
  menuItems: any;
  events: string[] = [];
  isSidenavOpen = false;  // Variable to track sidenav state
  @ViewChild('sidenav') sidenav!: MatSidenav;
  navbarData: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private apiService: ApiService,
  ) {

    this.navbarData = [
      {
        label: 'Home',
        route: '/',
        isExpanded: false
      },
      {
        label: 'Services',
        more: true,
        isExpanded: false,
        items: [
          {
            label: 'Managed Services',
            route: '/managed-services',
          },
          {
            label: 'Staffing',
            route: '/staffing',
          },
          {
            label: 'Consulting',
            more: true,
            isExpanded: false,
            items: [
              {
                label: 'What we do?',
                route: '/consulting',
              },
              {
                label: 'Cloud',
                route: '/consulting/cloud',
              },
              {
                label: 'Cloud Migration',
                route: '/consulting/cloud-migration',
              }
            ]
          }
        ]
      },
      {
        label: 'Technologies',
        more: true,
        isExpanded: false,
        items: [
          {
            label: 'What we do?',
            route: '/technology',
          },
          {
            label: 'Anaplan',
            route: '/anaplan',
          },
          {
            label: 'Workiva',
            route: '/workiva',
          },
          {
            label: 'Odoo',
            more: true,
            isExpanded: false,
            // route: '/odoo',
            items: [
              {
                label: 'Odoo Services',
                route: '/odoo/odoo-services',
              },
              {
                label: 'Odoo customization',
                route: '/odoo/customization',
              },
              {
                label: 'Odoo Implementation',
                route: '/odoo/implementation',
              },
              // {
              //   label: 'Odoo Integration',
              //   route: '/odoo/integration',
              // }
            ]
          }
        ]
      }
    ];
  }


  toggleExpand(item: any, isParent: boolean) {
    if (isParent) {
      this.navbarData.forEach((section: any) => {
        if (section !== item) {
          section.isExpanded = false;
        }
      });
    }
    item.isExpanded = !item.isExpanded;
  }

  toggleSidenav() {
    this.sidenav.toggle();
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  onSidenavOpen() {
    this.isSidenavOpen = true;
    this.resetExpandStates();
  }

  onSidenavClose() {
    this.isSidenavOpen = false;
    this.resetExpandStates();
  }

  handleLinkClick(route: string) {
    this.router.navigateByUrl(route);
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  openModal() {
    this.sidenav.toggle();
    this.modalService.open(ContactUsComponent, {
      windowClass: 'custom-modal-lg',
    });
  }

  private resetExpandStates() {
    this.navbarData.forEach((section: any) => {
      section.isExpanded = false;
      if (section.items) {
        section.items.forEach((item: any) => {
          item.isExpanded = false;
          if (item.items) {
            item.items.forEach((subItem: any) => {
              subItem.isExpanded = false;
            });
          }
        });
      }
    });
  }
}
