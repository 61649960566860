import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AxiosResponse } from 'axios';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlogImageSliderComponent } from '../blog-image-slider/blog-image-slider.component';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CaptchaComponent } from '../captcha/captcha.component';
import Swal from 'sweetalert2';
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [RouterModule, CommonModule, NgxSkeletonLoaderModule, ReactiveFormsModule, CaptchaComponent],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss'
})
export class BlogComponent {
  // sitekey = '6LfJTWUpAAAAAO-ksjFHtQiUbFIBusnn2ZRB48U2';
  getData: any;
  dynamicId: any;
  loading: boolean = true;

  styles = {
    'width.%': 100,
    'height.px': 300,
    'border-radius': '10px'
  }
  formData: any;
  subsbscribeForm: any;
  socialData: any;
  subscribeData: any;
  socialShareData: any;
  receivedToken: any = 'xyz';
  blogName: any;
  domain: string;
  timestamp: any;
  currentUrl: any;
  @ViewChild('InputField') InputField!: ElementRef;
  @ViewChild('subscribeForm') scrollToID!: ElementRef;

  constructor(
    private apiService: ApiService,
    private _changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private modalService: NgbModal,
    private titleService: Title,
    private meta: Meta,
    private formBuilder: FormBuilder,
    private canonicalService: CanonicalService
  ) {
    this.formData = new FormData();

    this.currentUrl = window.location.href;
    console.log(this.currentUrl);

    const urlParts = this.currentUrl.split('/');
    this.domain = urlParts[0] + '//' + urlParts[2] + '/' + urlParts[3] + '/';
  }


  ngOnInit() {
    // this.scrollToID = this.el.nativeElement.querySelector('#subscribeForm');
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);

      this.route.url.subscribe(urlSegments => {
        this.canonicalService.setCanonicalUrl(this.currentUrl);
    });
    }
    this.timestamp = new Date().toISOString();
    this.route.paramMap.subscribe(params => {
      this.dynamicId = params.get('id');
    })

    if (this.dynamicId) {
      this.loadBlogData();
    }

    // console.log(this.domain + this.dynamicId);


    this.subsbscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]]
    });

  }



  loadBlogData(): void {
    this.apiService.get(`blog/blog_detail?slug=${this.dynamicId}`).subscribe((response: AxiosResponse<unknown>) => {
      if (response) {
        this.getData = response?.data;
        this.loading = false;

        if (this.getData?.data?.service_id == 1) {
          this.blogName = 'Anaplan'
        } else {
          this.blogName = 'Workiva'
        }


        this.metaData();
      }
    },
      (error) => {
        this.loading = false;
      }
    );
  }

  metaData() {
    let metaTitle = this.getData?.data?.meta_title !== null ? this.getData.data.meta_title : this.getData.data.title;
    metaTitle = metaTitle + (this.getData?.data?.service_name ? (' | ' + this.getData?.data?.service_name) : '') + ' | AttributeX';
    const metaDescription = this.getData?.data?.meta_description !== null ? this.getData.data.meta_description : this.getData?.data?.title;
    const metaKeywords = this.getData?.data?.meta_keywords !== null ? this.getData.data.meta_keywords : this.getData.data.tags;
    const metaImage = this.getData.data.main_image;

    this.titleService.setTitle(metaTitle);
    this.meta.updateTag({ name: 'keywords', content: metaKeywords });
    this.meta.updateTag({ name: 'description', content: metaDescription });
    this.meta.updateTag({ name: 'title', content: metaTitle });
    // this.meta.updateTag({ rel: 'canonical', href: this.currentUrl });

    this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
    this.meta.updateTag({ property: 'og:type', content: 'blog' });
    this.meta.updateTag({ property: 'og:title', content: metaTitle });
    this.meta.updateTag({ property: 'og:cardTitle', content: metaTitle });
    this.meta.updateTag({ property: 'og:description', content: metaDescription });
    this.meta.updateTag({ property: 'og:cardDescription', content: metaDescription });
    this.meta.updateTag({ property: 'og:url', content: `${this.domain}${this.dynamicId}` });
    this.meta.updateTag({ property: 'og:printableUri', content: `${this.domain}${this.dynamicId}` });
    this.meta.updateTag({ property: 'og:site_name', content: 'AttributeX' });
    this.meta.updateTag({ property: 'og:image', content: metaImage });
    this.meta.updateTag({ property: 'og:cardImage', content: metaImage });
    this.meta.updateTag({ property: 'og:timestamp', content: this.timestamp });
    this.meta.updateTag({ property: 'og:tags', content: metaKeywords });

    this.meta.updateTag({ name: 'twitter:title', content: metaTitle });
    this.meta.updateTag({ name: 'twitter:description', content: metaDescription });
    this.meta.updateTag({ name: 'twitter:image:src', content: metaImage });
    this._changeDetectorRef.detectChanges();
  }

  imgSlider(image: any, data: any) {
    const modalRef = this.modalService.open(BlogImageSliderComponent, {
      windowClass: 'custom-modal-xl',
    })
    const sendData = { image: image, data: data }
    modalRef.componentInstance.data = sendData;
  }

  socialShare(value: any) {
    this.socialData = {
      slug: this.dynamicId,
      social_type: value
    }
    const formData = this.apiService.serializeForm(this.socialData);
    this.apiService.post(`blog/blog_social_share`, formData).subscribe((response: AxiosResponse<unknown>) => {
      if (response) {
        this.socialShareData = response?.data;
        if (isPlatformBrowser(this.platformId)) {
          window.open(this.socialShareData?.data?.redirect_url, '_blank');
        }
      }
    },
      (error) => {
        // this.loading = false;
      }
    );
  }

  scrollToId() {
    if (this.scrollToID) {
      this.scrollToID.nativeElement.scrollIntoView({ behavior: 'smooth' });
      this.InputField.nativeElement.focus();
    }
  }

  // receiveData(event: any) {
  //   this.receivedToken = event;
  //   console.log(this.receivedToken);
  // }

  emailSubscribe() {
    if (this.subsbscribeForm.invalid) {
      this.subsbscribeForm.markAllAsTouched();
      return
    } else {
      this.formData.append('email', this.subsbscribeForm.get('email').value);
      this.formData.append('g-recaptcha-response', this.receivedToken);

      this.apiService.post(`blog/subscribe_to_email`, this.formData).subscribe((response: AxiosResponse<unknown>) => {
        if (response) {
          this.subscribeData = response?.data;

          console.log(this.subscribeData);

          if (this.subscribeData.status == true) {
            this.modalService.dismissAll();
            Swal.fire('Success', `${this.subscribeData?.message}`, 'success').then(
              () => {
                this.subsbscribeForm.reset();
                if (isPlatformBrowser(this.platformId)) {
                  window.location.reload();
                }
                // this._router.navigateByUrl('/home');
              }
            );
          } else {
            Swal.fire('Error', `${this.subscribeData?.errors?.comments}`, 'error');
          }
        }
      },
        (error) => {
          // this.loading = false;
        }
      );
    }
  }
}
