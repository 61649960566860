import { Component } from '@angular/core';
import { ServiceComponent } from '../service.component';

@Component({
  selector: 'app-staffing',
  standalone: true,
  imports: [ServiceComponent],
  templateUrl: './staffing.component.html',
  styleUrl: './staffing.component.scss'
})
export class StaffingComponent {

}
