import { Component, OnInit } from '@angular/core';
import { ServiceComponent } from '../service.component';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs';

@Component({
  selector: 'app-consulting',
  standalone: true,
  imports: [ServiceComponent,RouterOutlet, CommonModule],
  templateUrl: './consulting.component.html',
  styleUrl: './consulting.component.scss'
})
export class ConsultingComponent implements OnInit{
  isChildRouteActive: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {}
  
  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // Determine if a child route is active
        this.isChildRouteActive = this.checkForChildRoutes(this.route.root);
      });

    // Initial check
    this.isChildRouteActive = this.checkForChildRoutes(this.route.root);
  }

  private checkForChildRoutes(route: ActivatedRoute): boolean {
    if (route.firstChild) {
      if (route.firstChild.snapshot.data['hideParent']) {
        return true;
      }
      return this.checkForChildRoutes(route.firstChild);
    }
    return false;
  }

}
