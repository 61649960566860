import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  private canonicalUrl: any;

  constructor(private titleService: Title) {}

  setCanonicalUrl(url: string) {
    this.canonicalUrl = url;
    this.updateCanonicalTag();
  }

  private updateCanonicalTag() {
    const canonicalLink: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', this.canonicalUrl);
    } else {
      const newCanonicalLink: HTMLLinkElement = document.createElement('link');
      newCanonicalLink.setAttribute('rel', 'canonical');
      newCanonicalLink.setAttribute('href', this.canonicalUrl);
      document.head.appendChild(newCanonicalLink);
    }
  }
}
