<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">

    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu" (click)="collapseHide(child)">
        <mat-icon *ngIf="child?.iconName">{{child.iconName}}</mat-icon>
        <span class="fw-bold">{{child.displayName}}</span>
      </button>
      <app-mega-menu #menu [items]="child.children"></app-mega-menu>
    </span>

    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item [routerLink]="child.route" (click)="collapseHide(child)">
        <mat-icon *ngIf="child?.iconName">{{child.iconName}}</mat-icon>
        <span class="fw-bold">{{child.displayName}}</span>
      </button>
    </span>
  </span>
</mat-menu>